import React from 'react';
import { Link } from 'gatsby';

import Layout from '../components/Layout';
import Title from '../components/Title';

// import ImageSample from '../../assets/images/sampleget.gif';

const processes = ['Digital', 'Hotfoil', 'Letterpress', 'Thermal'];
const materials = [
  '3M Products',
  'Aluminium',
  'Metalon',
  'Paper',
  'Polycarbonate',
  'Polycomp',
  'Polyester',
  'Polyethylene',
  'Polypropylene',
  'Satin',
  'Tag card',
  'Tyvec',
  'Vinyl'
];
const finishes = [
  'Antique',
  'Clear',
  'Coloured',
  'Gloss',
  'Matt',
  'Metallic',
  'Radiant',
  'Semi Gloss',
  'White',
  'plus many more'
];
const foils = ['Diffraction', 'Gloss Colours', 'Gold', 'Matt Colours', 'Metallic Colours', 'Silver'];
const adhesives = [
  'Blockout',
  "'Extra'",
  'Fabric',
  'Fastyre',
  'Freezer',
  'Peelable',
  'Permanent',
  'Tamper Evident',
  'Ultra Peelable',
  'Water Soluble',
  'plus many more'
];
const extras = [
  'Consecutive Barcoding',
  'Consecutive Numbering',
  'Embossing',
  'Fan Folded Labels',
  'Laminating',
  'Over 6000 Stock Cutters',
  'Overprinting',
  'Roll Labels',
  'Sheeted Labels',
  'Sprocket Punching',
  'Varnishing'
];

const List = props => (
  <div className="col-md-4 col-sm-6">
    <div className="c-content-media-1 c-bordered">
      <h3 className="c-title">
        <strong>{props.title}</strong>
      </h3>
      <div className="c-line-center c-theme-bg" />
      <div className="">
        <ul className="c-content-list-1 c-separator-dot c-theme">
          {props.data.map((d, idx) => (
            <li key={idx}>{d}</li>
          ))}
        </ul>
      </div>
    </div>
  </div>
);

const Products = () => (
  <Layout title="Products">
    <div className="c-content-box c-size-md">
      <div className="container c-font-center">
        <Title main="Products" />
        <p className="c-font-white c-font-center">
          All of the products listed on this website are supplied by Calf Hey with a strong emphasis placed on quality.{' '}
          <br />
          With a variety of print processes on offer, we can reproduce almost any label. The types of labels shown on
          this website are included in our sample pack.
        </p>
        <p className="c-font-white c-font-center">
          If you do not already have a sample pack -
          <Link to="/sample/">
            <strong className="c-font-white "> GET ONE COMPLETELY FREE </strong>
          </Link>
        </p>
      </div>
    </div>
    <div className="c-content-box">
      <Title
        main="Extra Information"
        helper="Below is a list of various products that we can supply. If the product you require is not listed, please contact us."
      />

      <div className="container">
        <div className="c-body">
          <div className="row">
            <List title="PROCESSES" data={processes} />
            <List title="MATERIALS" data={materials} />
            <List title="ADHESIVES" data={adhesives} />
          </div>
          <div className="row c-margin-t-30">
            <List title="FINISHES" data={finishes} />
            <List title="FOILS" data={foils} />
            <List title="EXTRAS" data={extras} />
          </div>
        </div>
      </div>

      <div className="c-content-box c-size-md">
        <div className="container">
          <div className="c-content-panel">
            <div className="c-body">
              <blockquote className="c-theme-border c-font-white">
                <p>
                  If the product you are looking for is not listed here –{' '}
                  <Link to="/contact/">
                    <strong style={{ color: 'white' }}>PLEASE PHONE FOR MORE INFORMATION.</strong>
                  </Link>
                </p>
              </blockquote>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
);

export default Products;
